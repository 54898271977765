import React from 'react'
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink
} from './NavbarElements'

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavLink to="/">
          <a href="/">
            <img src="/logo.png" width="50" alt="logo" />
          </a>
        </NavLink>
        <Bars />
        <NavMenu>
          <NavLink href="/about" activeStyle>
            About
          </NavLink>
          <NavLink href="/contact" activeStyle>
            Contact
          </NavLink>
        </NavMenu>
      </Nav>
    </>
  )
}

export default Navbar
