import 'styles/global.css'

import SEO from '@bradgarropy/gatsby-plugin-seo'
import Footer from 'components/Footer'
import Header from 'components/Navbar/Header'
import { AppProvider } from 'context'
import { FC } from 'react'

import * as styles from './Layout.module.css'

import favicon from '../Navbar/Logo/logo.png'
import './Layout.scss'
import Navbar from 'components/Navbar2'

const Layout: FC = ({ children }) => {
  return (
    <AppProvider>
      <SEO
        title="Hack the JavaScript Interview Process | Hacking the JS Interview"
        keywords={[
          'JavaScript Interviews',
          'JavaScript Interview Questions',
          'Frontend Interviews',
          'Frontend Interview Questions'
        ]}
        icon={favicon}
        facebook={{
          image: 'https://hackingthejsinterview.com/logo.png',
          url: 'https://hackingthejsinterview.com',
          type: 'website'
        }}
        twitter={{
          image: 'https://hackingthejsinterview.com/logo.png',
          site: '@yichenzhu1337',
          card: 'summary'
        }}
      />

      <div className="master-container">
        <div className="child-container">
          {/* <Header /> */}

          <Navbar />

          <main className="content">{children}</main>

          <Footer />
        </div>
      </div>
    </AppProvider>
  )
}

export default Layout
